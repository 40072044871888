export const basicDetail =
[
  {
    name:"locationId",
     "rule": {
        "required": true
      },
    label:"Location",
    type:"dropdown"
  },
  {
    name:"rateCodId",
     "rule": {
        "required": true
      },
    label:"Rate Code",
    type:"dropdown"
  },
  {
    name:"startDate",
     "rule": {
        "required": true
      },
    label:"Start Date",
    type:"date"
  },
  {
    name:"startTime",
     "rule": {
        "required": true
      },
    label:"Start Time",
    type:"hours"
  },
  {
    name:"endDate",
     "rule": {
        "required": true
      },
    label:"End Date",
    type:"date"
  },
  {
    name:"endTime",
     "rule": {
        "required": true
      },
    label:"End Time",
    type:"hours"
  },
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  },
  {
    name:"pickupAllowed",
     "rule": {
        "required": true
      },
    label:"Pickup Allowed",
    type:"checkbox"
  },
  {
    name:"spanoverAllowed",
     "rule": {
        "required": true
      },
    label:"Spanover Allowed",
    type:"checkbox"
  },
  {
    name:"networkId",
     "rule": {
        "required": false
      },
    label:"Network",
    type:"dropdown"
  },
  {
    name:"categoryId",
     "rule": {
        "required": false
      },
    label:"Classes",
    type:"dropdown"
  },
  {
    name:"blackoutMessage",
     "rule": {
        "required": false
      },
    label:"Blackout Message",
    type:"text"
  },
  
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const AppliesDD =
[
  { id:1, name:"Applies Per Day"},
  { id:2, name:"Applies By Percentage"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]

  export const hourslist =[
    {"id": "00:00", "name": "12:00 AM"},
    {"id": "00:30", "name": "12:30 AM"},
    {"id": "01:00", "name": "01:00 AM"},
    {"id": "01:30", "name": "01:30 AM"},
    {"id": "02:00", "name": "02:00 AM"},
    {"id": "02:30", "name": "02:30 AM"},
    {"id": "03:00", "name": "03:00 AM"},
    {"id": "03:30", "name": "03:30 AM"},
    {"id": "04:00", "name": "04:00 AM"},
    {"id": "04:30", "name": "04:30 AM"},
    {"id": "05:00", "name": "05:00 AM"},
    {"id": "05:30", "name": "05:30 AM"},
    {"id": "06:00", "name": "06:00 AM"},
    {"id": "06:30", "name": "06:30 AM"},
    {"id": "07:00", "name": "07:00 AM"},
    {"id": "07:30", "name": "07:30 AM"},
    {"id": "08:00", "name": "08:00 AM"},
    {"id": "08:30", "name": "08:30 AM"},
    {"id": "09:00", "name": "09:00 AM"},
    {"id": "09:30", "name": "09:30 AM"},
    {"id": "10:00", "name": "10:00 AM"},
    {"id": "10:30", "name": "10:30 AM"},
    {"id": "11:00", "name": "11:00 AM"},
    {"id": "11:30", "name": "11:30 AM"},
    {"id": "12:00", "name": "12:00 PM"},
    {"id": "12:30", "name": "12:30 PM"},
    {"id": "13:00", "name": "01:00 PM"},
    {"id": "13:30", "name": "01:30 PM"},
    {"id": "14:00", "name": "02:00 PM"},
    {"id": "14:30", "name": "02:30 PM"},
    {"id": "15:00", "name": "03:00 PM"},
    {"id": "15:30", "name": "03:30 PM"},
    {"id": "16:00", "name": "04:00 PM"},
    {"id": "16:30", "name": "04:30 PM"},
    {"id": "17:00", "name": "05:00 PM"},
    {"id": "17:30", "name": "05:30 PM"},
    {"id": "18:00", "name": "06:00 PM"},
    {"id": "18:30", "name": "06:30 PM"},
    {"id": "19:00", "name": "07:00 PM"},
    {"id": "19:30", "name": "07:30 PM"},
    {"id": "20:00", "name": "08:00 PM"},
    {"id": "20:30", "name": "08:30 PM"},
    {"id": "21:00", "name": "09:00 PM"},
    {"id": "21:30", "name": "09:30 PM"},
    {"id": "22:00", "name": "10:00 PM"},
    {"id": "22:30", "name": "10:30 PM"},
    {"id": "23:00", "name": "11:00 PM"},
    {"id": "23:30", "name": "11:30 PM"}
  ];