export let baseURL = window.BASE_URL;
if(window.APP_ROOT === "same") {
  baseURL = window.location.origin + "/api/";
}

export const API_URL ={
  login:"auth/login",
  forgot:"auth/password/forgot",
  resetpwd:"auth/password/reset",
  changepassword:"resetPassword",
  LOCATION:{
      GET:"getAllLocation",
      CREATE:"saveLocation",
      EDIT:"updateLocation",
      GET_BY_ID:"getLocation?locationId=:id",
      DELETE:"",
      GETCATEGORY:"getCategory",
      EXPORT:"downloadLocationsExcel",
      MODELDROPDOWN:"getDropDownVehicleLocation"
  },
  VEHICLE_CATEGORY:{
    GET:"getAllVehicleCategory",
    CREATE:"saveVehicleCategory",
    EDIT:"updateVehicleCategory",
    GET_BY_ID:"getVehicleCategory?categoryId=:id",
    DELETE:"",
    GETCATEGORY:"getCategory",
    EXPORT:"downloadCategoryExcel",
    MODELDROPDOWN:"getDropDownVehicleCategory"
},
UPLOAD_IMAGE:"documentUpload?userId=:id",
VEHICLE_MAKE:{
    GET:"getAllVehicleMake",
    CREATE:"saveVehicleMake",
    EDIT:"updateVehicleMake",
    GET_BY_ID:"getVehicleMake?makeId=:id",
    DELETE:"",
    EXPORT:"downloadMakeExcel",
    MAKEDROPDOWN:"getDropDownVehicleMake"
},
VEHICLE_MODEL:{
  GET:"getAllVehicleModel",
  CREATE:"saveVehicleModel",
  EDIT:"updateVehicleModel",
  GET_BY_ID:"getVehicleModel?modelId=:id",
  DELETE:"",
  EXPORT:"downloadModelExcel",
  MAKEDROPDOWN:"getDropDownVehicleModel"
},
VEHICLE_TRIM:{
  GET:"getAllVehicleTrim",
  CREATE:"saveVehicleTrim",
  EDIT:"updateVehicleTrim",
  GET_BY_ID:"getVehicleTrim?trimId=:id",
  DELETE:"",
  EXPORT:"downloadTrimExcel",
  MODELDROPDOWN:"getDropDownVehicleTrim"
},
VEHICLE_TRANSMISSIONTYPE:{
  GET:"getAllVehicleTransmissionType",
  CREATE:"saveVehicleTransmissionType",
  EDIT:"updateVehicleTransmissionType",
  GET_BY_ID:"getVehicleTransmissionType?transMissionId=:id",
  DELETE:"",
  EXPORT:"downloadTransmissionTypeExcel",
  MODELDROPDOWN:"getDropDownVehicleModel"
},
VEHICLE_ACTYPE:{
  GET:"getAllVehicleAcType",
  CREATE:"saveVehicleAcType",
  EDIT:"updateVehicleAcType",
  GET_BY_ID:"getVehicleAcType?acTypeId=:id",
  DELETE:"",
  EXPORT:"downloadAcTypeExcel",
  MODELDROPDOWN:"downloadAcTypeExcel"
},
VEHICLE_STEREOTYPE:{
  GET:"getAllVehicleStereoType",
  CREATE:"saveVehicleStereoType",
  EDIT:"updateVehicleStereoType",
  GET_BY_ID:"getVehicleStereoType?stereoTypeId=:id",
  DELETE:"",
  EXPORT:"downloadStereoTypeExcel",
  MODELDROPDOWN:"getDropDownVehicleModel"
},

VEHICLE_VEHICLESTATUS:{
  GET:"getAllVehicleStatus",
  CREATE:"saveVehicleStatus",
  EDIT:"updateVehicleStatus",
  GET_BY_ID:"getVehicleStatus?statusId=:id",
  DELETE:"",
  EXPORT:"downloadStatusExcel",
  MODELDROPDOWN:"getDropDownVehicleStatus"
},
VEHICLE_VEHICLEOPERATIONS:{
  GET:"getAllVehicleOperation",
  CREATE:"saveVehicleOperation",
  EDIT:"updateVehicleOperation",
  GET_BY_ID:"getVehicleOperation?vehicleId=:id",
  DELETE:"",
  EXPORT:"downloadOperationExcel",
  MODELDROPDOWN:"getDropDownVehicleModel"
},
VEHICLE_DEALERSHIPS:{
  GET:"getAllVehicleDealerShip",
  CREATE:"saveVehicleDealerShip",
  EDIT:"updateVehicleDealerShip",
  GET_BY_ID:"getVehicleDealerShip?dealerShipId=:id",
  DELETE:"",
  EXPORT:"downloadVehicleDealerShipExcel",
  MODELDROPDOWN:"getDropDownVehicleSupplier"
},
VEHICLE_BODYSHOP:{
  GET:"getAllVehicleBodyRepair",
  CREATE:"saveVehicleBodyRepairShop",
  EDIT:"updateVehicleBodyRepairShop",
  GET_BY_ID:"getVehicleBodyRepairShop?bodyRepairId=:id",
  DELETE:"",
  EXPORT:"downloadVehicleBodyRepairExcel",
  MODELDROPDOWN:"getDropDownVehicleModel"
},
VEHICLE_DEALERSHIPVEHICLEDEAL:{
  GET:"getAllVehicleDealerShipDeals",
  CREATE:"saveVehicleDealerShipDeals",
  EDIT:"updateVehicleDealerShipDeals",
  GET_BY_ID:"getVehicleDealerShipDeals?DealerShipDealsId=:id",
  DELETE:"",
  EXPORT:"downloadVehicleDealerShipDealsExcel",
  MODELDROPDOWN:"getDropDownVehicleModel"
},

RATEADMIN_RATE_CODE:{
  GET:"getAllRateCode",
  CREATE:"saveRateCode",
  EDIT:"updateRateCodeType",
  GET_BY_ID:"getRateCode?rateCodeId=:id",
  DELETE:"",
  EXPORT:"downloadRateCodeExcel",
  DROPDOWN:"getDropDownRateCode"
},
RATEADMIN_RATE_PLAN:{
  GET:"getAllRatePlan",
  CREATE:"saveRatePlan",
  EDIT:"updateRatePlanType",
  GET_BY_ID:"getRatePlan?ratePlanId=:id",
  DELETE:"",
  EXPORT:"downloadRatePlanExcel",
  DROPDOWN:"getDropDownRatePlan"
},

RATEADMIN_RATE_TYPES:{
  GET:"getAllRateType",
  CREATE:"saveRateType",
  EDIT:"updateRateTypeType",
  GET_BY_ID:"getRateType?rateTypeId=:id",
  DELETE:"",
  EXPORT:"downloadRateTypeExcel",
  MODELDROPDOWN:"getDropDownRateType"
},

RATEADMIN_LOR_ADJUSTMENTS:{
  GET:"getAllLorAdjustments",
  CREATE:"saveLorAdjustments",
  EDIT:"updateLorAdjustments",
  GET_BY_ID:"getLorAdjustments?lorAdjustmentsId=:id",
  DELETE:"",
  EXPORT:"downloadLorAdjustmentsExcel",
  MODELDROPDOWN:"getDropDownRateType"
},
RATEADMIN_RATES:{
  GET:"getAllRates",
  CREATE:"saveRate",
  EDIT:"updateRate",
  GET_BY_ID:"getRate?rateId=:id",
  DELETE:"deleteRate?rateId=:id",
  EXPORT:"downloadRateExcel",
  DROPDOWN:"getDropDownRatePlan",
  RATEOPTIONDD:"getDropDownRateOption",
  RATENETWORKDD:"getDropDownNetwork",
  SAVERATEADJUST:"saveRateAdjustment",
  GETRATEADJUST:"getRateAdjustment?rateId=:id"
},

RATEADMIN_EXTRAPRODUCTCATEGORY:{
  GET:"getAllExtraProductCategory",
  CREATE:"saveRateExtraProductCategory",
  EDIT:"updateRateExtraProductCategory",
  GET_BY_ID:"getRateExtraProductCategory?extraCategoryId=:id",
  DELETE:"",
  EXPORT:"downloadRateExtraProductCategoryExcel",
  DROPDOWN:"getDropDownRateExtraProductCategory"
},
RATEADMIN_EXTRAPRODUCTCHARGE:{
  GET:"getAllRateExtraProdCharge",
  CREATE:"saveRateExtraProdCharge",
  EDIT:"updateRateExtraProdCharge",
  GET_BY_ID:"getRateExtraProdCharge?extraProdId=:id",
  DELETE:"",
  EXPORT:"downloadExtraProdCharge",
  DROPDOWN:"getDropDownRateType"
},
RATEADMIN_NETWORKS:{
  GET:"getAllNetwork",
  CREATE:"saveNetwork",
  EDIT:"updateNetwork",
  GET_BY_ID:"getNetwork?extraProdId=:id",
  DELETE:"",
  EXPORT:"downloadNetworkExcel",
  DROPDOWN:"getDropDownNetwork"
},
RATEADMIN_BLACKOUTS:{
  GET:"getAllBlackout",
  CREATE:"saveBlackout",
  EDIT:"updateBlackout",
  GET_BY_ID:"getBlackOut?blackOutId=:id",
  DELETE:"",
  EXPORT:"downloadBlackOutExcel",
  DROPDOWN:"getDropDownNetwork"
},
RATEADMIN_RATEMIRRORS:{
  GET:"getAllRateMirror",
  CREATE:"saveRateMirror",
  EDIT:"updateRateMirror",
  GET_BY_ID:"getRateMirror?rateMirrorId=:id",
  DELETE:"deleteRateMirror?rateMirrorId=:id",
  EXPORT:"downloadRateMirrorExcel",
  DROPDOWN:"getDropDownRateMirror"
},

}


export const APP_URLS ={
  CHANGEPASSWORD:"/chagne-password",
   LOCATION:{
    HOME:"/location",
    CREATE:"/createlocation",
    EDIT:"/locations/:id/edit"
   },
   VEHICLE_CATEGORY:{
    HOME:"/vehicle-category",
    CREATE:"/create-vehicle-category",
    EDIT:"/create-vehicle-category/:id/edit"
   },
   VEHICLE_MAKE:{
    HOME:"/vehicle-make",
    CREATE:"/create-vehicle-make",
    EDIT:"/create-vehicle-make/:id/edit"
   },
   VEHICLE_MODEL:{
    HOME:"/vehicle-model",
    CREATE:"/create-vehicle-model",
    EDIT:"/create-vehicle-model/:id/edit"
   },
   VEHICLE_TRIM:{
    HOME:"/vehicle-trim",
    CREATE:"/create-vehicle-trim",
    EDIT:"/create-vehicle-trim/:id/edit"
   },
   VEHICLE_TRANSMISSIONTYPE:{
    HOME:"/vehicle-transmission-type",
    CREATE:"/create-vehicle-transmission-type",
    EDIT:"/create-vehicle-transmission-type/:id/edit"
   },
   VEHICLE_ACTYPE:{
    HOME:"/vehicle-ac-type",
    CREATE:"/create-vehicle-ac-type",
    EDIT:"/create-vehicle-ac-type/:id/edit"
   },
   VEHICLE_STEREOTYPE:{
    HOME:"/vehicle-stereo-type",
    CREATE:"/create-vehicle-stereo-type",
    EDIT:"/create-vehicle-stereo-type/:id/edit"
   },
   VEHICLE_VEHICLESTATUS:{
    HOME:"/vehicle-status",
    CREATE:"/create-status",
    EDIT:"/create-status/:id/edit"
   },
   VEHICLE_VEHICLEOPERATIONS:{
    HOME:"/vehicle-operations",
    CREATE:"/create-vehicle-operations",
    EDIT:"/create-vehicle-operations/:id/edit"
   },
   VEHICLE_DEALERSHIPS:{
    HOME:"/vehicle-dealerships",
    CREATE:"/create-dealerships",
    EDIT:"/create-dealerships/:id/edit"
   },
   
   VEHICLE_BODYSHOP:{
    HOME:"/vehicle-body-shop",
    CREATE:"/create-body-shop",
    EDIT:"/create-body-shop/:id/edit"
   },
   VEHICLE_DEALERSHIPVEHICLEDEAL:{
    HOME:"/vehicle-dealership-vehicle-deal",
    CREATE:"/create-dealership-vehicle-deal",
    EDIT:"/create-dealership-vehicle-deal/:id/edit"
   },
   RATEADMIN_RATE_CODE:{
    HOME:"/rate-code",
    CREATE:"/create-rate-code",
    EDIT:"/create-rate-code/:id/edit"
   },
   RATEADMIN_RATE_PLAN:{
    HOME:"/rate-plans",
    CREATE:"/create-rate-plan",
    EDIT:"/create-rate-plan/:id/edit"
   },
   RATEADMIN_RATE_TYPES:{
    HOME:"/rate-types",
    CREATE:"/create-rate-types",
    EDIT:"/create-rate-types/:id/edit"
   },
   RATEADMIN_LOR_ADJUSTMENTS:{
    HOME:"/lor-adjustments",
    CREATE:"/create-lor-adjustments",
    EDIT:"/create-lor-adjustments/:id/edit"
   },
   RATEADMIN_RATES:{
    HOME:"/rates",
    CREATE:"/create-rates",
    EDIT:"/create-rates/:id/edit",
    COPY:"/create-rates/:id/copy"
   },
   RATEADMIN_EXTRAPRODUCTCATEGORY:{
    HOME:"/extra-product-category",
    CREATE:"/create-extra-product-category",
    EDIT:"/create-extra-product-category/:id/edit",
   },
   RATEADMIN_EXTRAPRODUCTCHARGE:{
    HOME:"/extra-product-charge",
    CREATE:"/create-extra-product-charge",
    EDIT:"/create-extra-product-charge/:id/edit",
    COPY:"/create-extra-product-charge/:id/copy"
   },
   RATEADMIN_NETWORK:{
    HOME:"/networks",
    CREATE:"/create-networks",
    EDIT:"/create-networks/:id/edit",
   },
   RATEADMIN_BLACKOUTS:{
    HOME:"/blackouts",
    CREATE:"/create-blackouts",
    EDIT:"/create-blackouts/:id/edit",
   },
   RATEADMIN_RATEMIRRORS:{
    HOME:"/rate-mirror",
    CREATE:"/create-rate-mirror",
    EDIT:"/create-rate-mirror/:id/edit",
   },
}