export const FORM_FIELDS_NAME = {
  ADDRESS: "address",
  AFTERHOURFEE: "afterHourFee",
  AFTERHOURFEESTATUS: "afterHourFeeStatus",
  AIRPORTCHARGESTATUS: "airportChargeStatus",
  AIRPORTCODE: "airportCode",
  AIRPORTCONCESSIONFEERATE: "airportConcessionFeeRate",
  AIRPORTFACILITYCHARGE: "airportFacilityCharge",
  AIRPORTFACILITYCHARGEMAXDAYS: "airportFacilityChargeMaxDays",
  ANYAIRPORTLOCATION: "anyAirportLocation",
  CATEGORYID: "categoryId",
  CITY: "city",
  CONCESSIONFEETYPE: "concessionFeeType",
  CONTACT: "contact",
  COUNTRY: "country",
  CRDBY: "crdBy",
  CRTTIME: "crtTime",
  CURRENCYCODE: "currencyCode",
  ENERGYRECOVERYFEE: "energyRecoveryFee",
  FRIAFTERHOUREND: "friAfterHourEnd",
  FRIENDHOUR: "friEndHour",
  FRISTARTHOUR: "friStartHour",
  GMAPLINK: "gmapLink",
  ID: "id",
  LATITUDE: "latitude",
  LOCATIONEXTRAHOURREQUESTS: [
    {
      AFTERENDHOUR: "afterEndHour",
      ENDDATE: "endDate",
      ENDHOUR: "endHour",
      ID: "id",
      LOCATIONID: "locationId",
      STARTDATE: "startDate",
      STARTHOUR: "startHour",
      STATUS: "status"
    }
  ],
  LOCATIONNAME: "locationName",
  LOCATIONPOLICY: "locationPolicy",
  LONGITUDE: "longitude",
  MAINTENANCEFEE: "maintenanceFee",
  MODBY: "modBy",
  MODTIME: "modTime",
  MONAFTERHOUREND: "monAfterHourEnd",
  MONENDHOUR: "monEndHour",
  MONSTARTHOUR: "monStartHour",
  PIN: "pin",
  PREFIXID: "prefixId",
  PROVINCE: "province",
  RESPREFIX: "resPrefix",
  ROADSIDESERVICE: "roadSideService",
  SATAFTERHOUREND: "satAfterHourEnd",
  SATENDHOUR: "satEndHour",
  SATSTARTHOUR: "satStartHour",
  SHUTTLEHOURS: "shuttleHours",
  STATUS: "status",
  SUNAFTERHOUREND: "sunAfterHourEnd",
  SUNENDHOUR: "sunEndHour",
  SUNSTARTHOUR: "sunStartHour",
  TAX: "tax",
  TAXPERCENT: "taxPercent",
  THUAFTERHOUREND: "thuAfterHourEnd",
  THUENDHOUR: "thuEndHour",
  THUSTARTHOUR: "thuStartHour",
  TUEAFTERHOUREND: "tueAfterHourEnd",
  TUEENDHOUR: "tueEndHour",
  TUESTARTHOUR: "tueStartHour",
  UNDERAGERATE: "underageRate",
  USERID: "userId",
  USERNAME: "username",
  VEHICLELICENSEFEE: "vehicleLicenseFee",
  WEDAFTERHOUREND: "wedAfterHourEnd",
  WEDENDHOUR: "wedEndHour",
  WEDSTARTHOUR: "wedStartHour",
  WEEKENDHOUREND: "weekEndHourEnd",
  WEEKENDHOURSTART: "weekEndHourStart",
  WEEKHOUREND: "weekHourEnd",
  WEEKHOURSTART: "weekHourStart",
  WORKINGHOURSTARTEND: "workingHourStartEnd"
};


const FORM_FIELDS_DATA =[
 ,{
    name:"locationPolicy",
     "rule": {
        "required": true
      },
    label:"Location Policy"
  }
]
export const basicDetail =
[
  {
    name:"locationName",
     "rule": {
        "required": true
      },
    label:"Location Name"
  },{
    name:"address",
     "rule": {
        "required": true
      },
    label:"Address"
  },{
    name:"city",
     "rule": {
        "required": false
      },
    label:"City"
  },{
    name:"province",
     "rule": {
        "required": false
      },
    label:"Province"
  },{
    name:"country",
     "rule": {
        "required": true
      },
    label:"Country"
  },{
    name:"pin",
     "rule": {
        "required": true
      },
    label:"PIN"
  },{
    name:"contact",
     "rule": {
        "required": true
      },
    label:"Contact Number"
  },{
    name:"gmapLink",
     "rule": {
        "required": false
      },
    label:"GMap Link"
  },{
    name:"airportCode",
     "rule": {
        "required": false
      },
    label:"Airport Code"
  },{
    name:"currencyCode",
     "rule": {
        "required": false
      },
    label:"Currency Code"
  },{
    name:"resPrefix",
     "rule": {
        "required": false
      },
    label:"Reservation ID Prefix"
  },{
    name:"latitude",
     "rule": {
        "required": false
      },
    label:"Latitude"
  },{
    name:"longitude",
     "rule": {
        "required": false
      },
    label:"Longitude"
  },{
    name:"anyAirportLocation",
     "rule": {
        "required": true
      },
    label:"Any Airport Location"
  },{
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status"
  },{
    name:"weekHourStart",
     "rule": {
        "required": false
      },
    label:"Weekday Working Hour Start"
  },{
    name:"weekHourEnd",
     "rule": {
        "required": false
      },
    label:"Weekday Working Hour End"
  },{
    name:"weekEndHourStart",
     "rule": {
        "required": false
      },
    label:"Weekend Working Hour Start"
  },{
    name:"weekEndHourEnd",
     "rule": {
        "required": false
      },
    label:"Weekend Working Hour End"
  }
  
]
export const hoursSetting =
[
  {
    name:"monStartHour",
     "rule": {
        "required": true
      },
    label:"Start Hour"
  },
  {
    name:"monEndHour",
     "rule": {
        "required": true
      },
    label:"End Hour"
  },
  {
    name:"monAfterHourEnd",
     "rule": {
        "required": true
      },
    label:"After Hour End"
  },
  {
    name:"tueStartHour",
     "rule": {
        "required": true
      },
    label:"Start Hour"
  },
  {
    name:"tueEndHour",
     "rule": {
        "required": true
      },
    label:"End Hour"
  },
  {
    name:"tueAfterHourEnd",
     "rule": {
        "required": true
      },
    label:"After Hour End"
  },
  {
    name:"wedStartHour",
     "rule": {
        "required": true
      },
    label:"Start Hour"
  },
  {
    name:"wedEndHour",
     "rule": {
        "required": true
      },
    label:"End Hour"
  },
  {
    name:"wedAfterHourEnd",
     "rule": {
        "required": true
      },
    label:"After Hour End"
  },
  {
    name:"thuStartHour",
     "rule": {
        "required": true
      },
    label:"Start Hour"
  },
  {
    name:"thuEndHour",
     "rule": {
        "required": true
      },
    label:"End Hour"
  },
  {
    name:"thuAfterHourEnd",
     "rule": {
        "required": true
      },
    label:"After Hour End"
  },
  {
    name:"friStartHour",
     "rule": {
        "required": true
      },
    label:"Start Hour"
  },
  {
    name:"friEndHour",
     "rule": {
        "required": true
      },
    label:"End Hour"
  },
  {
    name:"friAfterHourEnd",
     "rule": {
        "required": true
      },
    label:"After Hour End"
  },
  {
    name:"satStartHour",
     "rule": {
        "required": true
      },
    label:"Start Hour"
  },
  {
    name:"satEndHour",
     "rule": {
        "required": true
      },
    label:"End Hour"
  },
  {
    name:"satAfterHourEnd",
     "rule": {
        "required": true
      },
    label:"After Hour End"
  },
  {
    name:"sunStartHour",
     "rule": {
        "required": true
      },
    label:"Start Hour"
  },
  {
    name:"sunEndHour",
     "rule": {
        "required": true
      },
    label:"End Hour"
  },
  {
    name:"sunAfterHourEnd",
     "rule": {
        "required": true
      },
    label:"After Hour End"
  },
]

export const locationExtraHoursSetting=[
  {
    name:"startDate",
     "rule": {
        "required": false
      },
    label:"Start Date"
  },{
    name:"endDate",
     "rule": {
        "required": false
      },
    label:"End Date"
  },{
    name:"startHour",
     "rule": {
        "required": false
      },
    label:"Start Hour"
  },{
    name:"endHour",
     "rule": {
        "required": false
      },
    label:"End Hour"
  },{
    name:"afterEndHour",
     "rule": {
        "required": false
      },
    label:"After End Hour"
  }
]
export const freesCharges =[
  {
    name:"taxPercent",
     "rule": {
        "required": true
      },
    label:"Tax Percent"
  }, 
  {
    name:"afterHourFee",
    "rule": {
      "required": false
    },
    label:"After Hour Fee"
  },{
    name:"afterHourFeeStatus",
    "rule": {
      "required": true
    },
    label:"After Hour Fee Status"
  },{
    name:"energyRecoveryFee",
     "rule": {
        "required": true
      },
    label:"Energy Recovery Fee"
  },{
    name:"maintenanceFee",
     "rule": {
        "required": true
      },
    label:"Maintenance Fee"
  },{
    name:"vehicleLicenseFee",
     "rule": {
        "required": true
      },
    label:"Vehicle License Fee"
  },
  {
    name:"roadSideService",
     "rule": {
        "required": true
      },
    label:"Road Side Service"
  },{
    name:"airportFacilityCharge",
     "rule": {
        "required": true
      },
    label:"Airport Facility Charge"
  },
  {
    name:"airportFacilityChargeMaxDays",
     "rule": {
        "required": true
      },
    label:"Airport Facility Charge Max Days"
  },
  {
    name:"concessionFeeType",
     "rule": {
        "required": true
      },
    label:"Concession Fee Type"
  },
  {
    name:"airportConcessionFeeRate",
     "rule": {
        "required": true
      },
    label:"Airport Concession Fee Rate"
  },
  {
    name:"underageRate",
     "rule": {
        "required": true
      },
    label:"Underage Rate"
  },
  {
    name:"airportChargeStatus",
     "rule": {
        "required": true
      },
    label:"Airport Charge Status"
  }
]

export const hourslist =[
  {"id": "00:00", "name": "12:00 AM"},
  {"id": "00:30", "name": "12:30 AM"},
  {"id": "01:00", "name": "01:00 AM"},
  {"id": "01:30", "name": "01:30 AM"},
  {"id": "02:00", "name": "02:00 AM"},
  {"id": "02:30", "name": "02:30 AM"},
  {"id": "03:00", "name": "03:00 AM"},
  {"id": "03:30", "name": "03:30 AM"},
  {"id": "04:00", "name": "04:00 AM"},
  {"id": "04:30", "name": "04:30 AM"},
  {"id": "05:00", "name": "05:00 AM"},
  {"id": "05:30", "name": "05:30 AM"},
  {"id": "06:00", "name": "06:00 AM"},
  {"id": "06:30", "name": "06:30 AM"},
  {"id": "07:00", "name": "07:00 AM"},
  {"id": "07:30", "name": "07:30 AM"},
  {"id": "08:00", "name": "08:00 AM"},
  {"id": "08:30", "name": "08:30 AM"},
  {"id": "09:00", "name": "09:00 AM"},
  {"id": "09:30", "name": "09:30 AM"},
  {"id": "10:00", "name": "10:00 AM"},
  {"id": "10:30", "name": "10:30 AM"},
  {"id": "11:00", "name": "11:00 AM"},
  {"id": "11:30", "name": "11:30 AM"},
  {"id": "12:00", "name": "12:00 PM"},
  {"id": "12:30", "name": "12:30 PM"},
  {"id": "13:00", "name": "01:00 PM"},
  {"id": "13:30", "name": "01:30 PM"},
  {"id": "14:00", "name": "02:00 PM"},
  {"id": "14:30", "name": "02:30 PM"},
  {"id": "15:00", "name": "03:00 PM"},
  {"id": "15:30", "name": "03:30 PM"},
  {"id": "16:00", "name": "04:00 PM"},
  {"id": "16:30", "name": "04:30 PM"},
  {"id": "17:00", "name": "05:00 PM"},
  {"id": "17:30", "name": "05:30 PM"},
  {"id": "18:00", "name": "06:00 PM"},
  {"id": "18:30", "name": "06:30 PM"},
  {"id": "19:00", "name": "07:00 PM"},
  {"id": "19:30", "name": "07:30 PM"},
  {"id": "20:00", "name": "08:00 PM"},
  {"id": "20:30", "name": "08:30 PM"},
  {"id": "21:00", "name": "09:00 PM"},
  {"id": "21:30", "name": "09:30 PM"},
  {"id": "22:00", "name": "10:00 PM"},
  {"id": "22:30", "name": "10:30 PM"},
  {"id": "23:00", "name": "11:00 PM"},
  {"id": "23:30", "name": "11:30 PM"}
];
export const YesNoDrop =
[
  { id:1, name:"Yes"},
  { id:2, name:"No"}
];
export const statusDrop =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const afterHourFeeStatusDrop =
[
  { id:1, name:"Enable"},
  { id:2, name:"Disable"}
]
export const airportChargeStatusDrop =
[
  { id:1, name:"Enable"},
  { id:0, name:"Disable"}
]
export const concessionFeeTypeDrop =
[
  { id:1, name:"Percentage of Base Rate"},
  { id:2, name:"One Time"}
]


export const policyBreakdownlist =[
  {
    "name": "pcHrsOperations",
    "rule": {
      "required": false
    },
    "label": "Hours of Operations"
  },
  {
    "name": "pcDriverLicense",
    "rule": {
      "required": false
    },
    "label": "Driver License"
  },
  {
    "name": "pcSecurityDeposit",
    "rule": {
      "required": false
    },
    "label": "Security Deposit"
  },
  {
    "name": "pcPaymentMethod",
    "rule": {
      "required": false
    },
    "label": "Payment Method"
  },
  {
    "name": "pcPickupDropoff",
    "rule": {
      "required": false
    },
    "label": "Pickup/Dropoff"
  },
  {
    "name": "pcPickupDirection",
    "rule": {
      "required": false
    },
    "label": "Pickup Direction"
  },
  {
    "name": "pcMileageRestriction",
    "rule": {
      "required": false
    },
    "label": "Mileage Restriction"
  },
  {
    "name": "pcFuelPolicy",
    "rule": {
      "required": false
    },
    "label": "Fuel Policy"
  },
  {
    "name": "pcOutOfProvince",
    "rule": {
      "required": false
    },
    "label": "Out of Province"
  },
  {
    "name": "pcZoomCommitment",
    "rule": {
      "required": false
    },
    "label": "Zoom Commitment"
  },
  {
    "name": "pcCancellation",
    "rule": {
      "required": false
    },
    "label": "Cancellation"
  },
  {
    "name": "pcEarlyReturns",
    "rule": {
      "required": false
    },
    "label": "Early Returns"
  },
  {
    "name": "pcRentalExtension",
    "rule": {
      "required": false
    },
    "label": "Rental Extension"
  },
  {
    "name": "pcLdwCdw",
    "rule": {
      "required": false
    },
    "label": "LDW/CDW"
  },
  {
    "name": "pcNonSpecial",
    "rule": {
      "required": false
    },
    "label": "Non-Special"
  },
  {
    "name": "pcSpecialLdw",
    "rule": {
      "required": false
    },
    "label": "Special LDW"
  },
  {
    "name": "pcOtherServices",
    "rule": {
      "required": false
    },
    "label": "Other Services"
  },
  {
    "name": "pcAgeRequirement",
    "rule": {
      "required": false
    },
    "label": "Age Requirement"
  },
  {
    "name": "pcAdditionalDriver",
    "rule": {
      "required": false
    },
    "label": "Additional Driver"
  },
  {
    "name": "pcDrivingArea",
    "rule": {
      "required": false
    },
    "label": "Driving Area"
  },
  {
    "name": "pcMovingTruck",
    "rule": {
      "required": false
    },
    "label": "Moving Truck"
  },

  {
    "name": "pcAddress",
    "rule": {
      "required": false
    },
    "label": "Address"
  },
  {
    "name": "pcDisclaimer",
    "rule": {
      "required": false
    },
    "label": "Disclaimer"
  },
  {
    "name": "pcStandardTerms",
    "rule": {
      "required": false
    },
    "label": "Standard Terms"
  },
  {
    "name": "pcUseOfVehicle",
    "rule": {
      "required": false
    },
    "label": "Use of Vehicle"
  },
  {
    "name": "pcDeliveryReturn",
    "rule": {
      "required": false
    },
    "label": "Delivery/Return"
  },
  {
    "name": "pcPaymentCharge",
    "rule": {
      "required": false
    },
    "label": "Payment Charge"
  },  
  {
    "name": "pcRentalDamage",
    "rule": {
      "required": false
    },
    "label": "Rental Damage"
  },
  {
    "name": "pcInsuranceValidity",
    "rule": {
      "required": false
    },
    "label": "Insurance Validity"
  },
  {
    "name": "pcExclusion",
    "rule": {
      "required": false
    },
    "label": "Exclusion"
  }]

  export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]