import React from 'react'
import {  Routes, Route } from "react-router-dom";
import Login from '../container/Login';
import Dashboard from '../container/DashboardScreen';
import LocationScreen from '../container/LocationScreen';
import NotFound from '../NotFound';
import CreateLocationContainer from '../container/LocationScreen/CreateLocation';
import { APP_URLS } from '../utils/constant';
import VehicleCategoryScreen from '../container/Vehicles/VehicleCategoryScreen';
import CreateVehicleCategory from '../container/Vehicles/VehicleCategoryScreen/CreateVehicleCategory';
import VehicleMakeScreen from '../container/Vehicles/VehicleMakeScreen';
import CreateVehicleMake from '../container/Vehicles/VehicleMakeScreen/CreateVehicleMake';

import VehicleModelScreen from '../container/Vehicles/VehicleModelScreen';
import CreateVehicleModel from '../container/Vehicles/VehicleModelScreen/CreateVehicleModel';
import VehicleTrimScreen from '../container/Vehicles/VehicleTrimScreen';
import CreateVehicleTrim from '../container/Vehicles/VehicleTrimScreen/CreateVehicleTrim';
import VehicleTransmissionTypeScreen from '../container/Vehicles/VehicleTransmissionTypeScreen';
import CreateVehicleTransmissionType from '../container/Vehicles/VehicleTransmissionTypeScreen/CreateVehicleTransmissionType';
import VehicleAcTypeScreen from '../container/Vehicles/VehicleAcTypeScreen';
import CreateVehicleAcType from '../container/Vehicles/VehicleAcTypeScreen/CreateVehicleAcType';
import VehicleStereoTypeScreen from '../container/Vehicles/VehicleStereoTypeScreen';
import CreateVehicleStereoType from '../container/Vehicles/VehicleStereoTypeScreen/CreateVehicleStereoType';
import ResetPassword from '../container/Login/Reset';
import CreatePasswordContainer from '../container/ProfileScreen/CreateChangePassword';
import VehicleVehicleStatusScreen from '../container/Vehicles/VehicleVehicleStatusScreen';
import CreateVehicleVehicleStatus from '../container/Vehicles/VehicleVehicleStatusScreen/CreateVehicleVehicleStatus';
import VehicleVehicleOperationsScreen from '../container/Vehicles/VehicleVehicleOperationsScreen';
import CreateVehicleVehicleOperations from '../container/Vehicles/VehicleVehicleOperationsScreen/CreateVehicleVehicleOperations';
import VehicleDealershipsScreen from '../container/Vehicles/VehicleDealershipsScreen';
import CreateVehicleDealerships from '../container/Vehicles/VehicleDealershipsScreen/CreateVehicleDealerships';
import VehicleBodyShopScreen from '../container/Vehicles/VehicleBodyShopScreen';
import CreateVehicleBodyShop from '../container/Vehicles/VehicleBodyShopScreen/CreateVehicleBodyShop';
import VehicleDealershipVehicleDealScreen from '../container/Vehicles/VehicleDealershipVehicleDealScreen';
import CreateVehicleDealershipVehicleDeal from '../container/Vehicles/VehicleDealershipVehicleDealScreen/CreateVehicleDealershipVehicleDeal';
import RateAdminRateCodeScreen from '../container/RateAdminScreen/RateAdminRateCodeScreen';
import CreateRateAdminRateCode from '../container/RateAdminScreen/RateAdminRateCodeScreen/CreateRateAdminRateCode';
import RateAdminRatePlanScreen from '../container/RateAdminScreen/RateAdminRatePlanScreen';
import CreateRateAdminRatePlan from '../container/RateAdminScreen/RateAdminRatePlanScreen/CreateRateAdminRatePlan';
import RateAdminRateTypesScreen from '../container/RateAdminScreen/RateAdminRateTypesScreen';
import CreateRateAdminRateTypes from '../container/RateAdminScreen/RateAdminRateTypesScreen/CreateRateAdminRateTypes';
import RateAdminLorAdjustmentsScreen from '../container/RateAdminScreen/RateAdminLorAdjustmentsScreen';
import CreateRateAdminLorAdjustments from '../container/RateAdminScreen/RateAdminLorAdjustmentsScreen/CreateRateAdminLorAdjustments';
import CreateRateAdminRates from '../container/RateAdminScreen/RateAdminRatesScreen/CreateRateAdminRates';
import RateAdminRatesScreen from '../container/RateAdminScreen/RateAdminRatesScreen';
import RateAdminExtraProductCategoryScreen from '../container/RateAdminScreen/RateAdminExtraProductCategoryScreen';
import CreateRateAdminExtraProductCategory from '../container/RateAdminScreen/RateAdminExtraProductCategoryScreen/CreateRateAdminExtraProductCategory';
import RateAdminExtraProductChargeScreen from '../container/RateAdminScreen/RateAdminExtraProductChargeScreen';
import CreateRateAdminExtraProductCharge from '../container/RateAdminScreen/RateAdminExtraProductChargeScreen/CreateRateAdminExtraProductCharge';
import RateAdminNetworksScreen from '../container/RateAdminScreen/RateAdminNetworksScreen';
import CreateRateAdminNetworks from '../container/RateAdminScreen/RateAdminNetworksScreen/CreateRateAdminNetworks';
import RateAdminBlackoutsScreen from '../container/RateAdminScreen/RateAdminBlackoutsScreen';
import CreateRateAdminBlackouts from '../container/RateAdminScreen/RateAdminBlackoutsScreen/CreateRateAdminBlackouts';
import RateAdminRateMirrorsScreen from '../container/RateAdminScreen/RateAdminRateMirrorsScreen';
import CreateRateAdminRateMirrors from '../container/RateAdminScreen/RateAdminRateMirrorsScreen/CreateRateAdminRateMirrors';
export default function Router(props) {
  const Update = () =>{
    props.switchHeader()
  }

  return (
    <Routes>
         <Route exact={true} path="/" element={<Login callBack={Update}/>} />
         <Route path="/reset" element={<ResetPassword />} />
         <Route path="/dashboard" element={<Dashboard />} />
         <Route path="/location" element={<LocationScreen  />} />
         <Route path={APP_URLS.CHANGEPASSWORD} element={<CreatePasswordContainer  />} /> 

         <Route path={APP_URLS.LOCATION.CREATE} element={<CreateLocationContainer  />} /> 
         <Route path={APP_URLS.LOCATION.EDIT} element={<CreateLocationContainer isEdit={true} />} /> 
         <Route path={APP_URLS.VEHICLE_CATEGORY.HOME} element={<VehicleCategoryScreen  />} />
         <Route path={APP_URLS.VEHICLE_CATEGORY.CREATE} element={<CreateVehicleCategory  />} />
         <Route path={APP_URLS.VEHICLE_CATEGORY.EDIT} element={<CreateVehicleCategory isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_MAKE.HOME} element={<VehicleMakeScreen  />} />
         <Route path={APP_URLS.VEHICLE_MAKE.CREATE} element={<CreateVehicleMake  />} />
         <Route path={APP_URLS.VEHICLE_MAKE.EDIT} element={<CreateVehicleMake isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_MODEL.HOME} element={<VehicleModelScreen  />} />
         <Route path={APP_URLS.VEHICLE_MODEL.CREATE} element={<CreateVehicleModel  />} />
         <Route path={APP_URLS.VEHICLE_MODEL.EDIT} element={<CreateVehicleModel isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_TRIM.HOME} element={<VehicleTrimScreen  />} />
         <Route path={APP_URLS.VEHICLE_TRIM.CREATE} element={<CreateVehicleTrim  />} />
         <Route path={APP_URLS.VEHICLE_TRIM.EDIT} element={<CreateVehicleTrim isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_TRANSMISSIONTYPE.HOME} element={<VehicleTransmissionTypeScreen  />} />
         <Route path={APP_URLS.VEHICLE_TRANSMISSIONTYPE.CREATE} element={<CreateVehicleTransmissionType  />} />
         <Route path={APP_URLS.VEHICLE_TRANSMISSIONTYPE.EDIT} element={<CreateVehicleTransmissionType isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_ACTYPE.HOME} element={<VehicleAcTypeScreen  />} />
         <Route path={APP_URLS.VEHICLE_ACTYPE.CREATE} element={<CreateVehicleAcType  />} />
         <Route path={APP_URLS.VEHICLE_ACTYPE.EDIT} element={<CreateVehicleAcType isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_STEREOTYPE.HOME} element={<VehicleStereoTypeScreen  />} />
         <Route path={APP_URLS.VEHICLE_STEREOTYPE.CREATE} element={<CreateVehicleStereoType  />} />
         <Route path={APP_URLS.VEHICLE_STEREOTYPE.EDIT} element={<CreateVehicleStereoType isEdit={true} />} />


         <Route path={APP_URLS.VEHICLE_VEHICLESTATUS.HOME} element={<VehicleVehicleStatusScreen  />} />
         <Route path={APP_URLS.VEHICLE_VEHICLESTATUS.CREATE} element={<CreateVehicleVehicleStatus   />} />
         <Route path={APP_URLS.VEHICLE_VEHICLESTATUS.EDIT} element={<CreateVehicleVehicleStatus isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_VEHICLEOPERATIONS.HOME} element={<VehicleVehicleOperationsScreen  />} />
         <Route path={APP_URLS.VEHICLE_VEHICLEOPERATIONS.CREATE} element={<CreateVehicleVehicleOperations   />} />
         <Route path={APP_URLS.VEHICLE_VEHICLEOPERATIONS.EDIT} element={<CreateVehicleVehicleOperations isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_DEALERSHIPS.HOME} element={<VehicleDealershipsScreen  />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPS.CREATE} element={<CreateVehicleDealerships   />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPS.EDIT} element={<CreateVehicleDealerships isEdit={true} />} />
       
       
         <Route path={APP_URLS.VEHICLE_BODYSHOP.HOME} element={<VehicleBodyShopScreen  />} />
         <Route path={APP_URLS.VEHICLE_BODYSHOP.CREATE} element={<CreateVehicleBodyShop />} />
         <Route path={APP_URLS.VEHICLE_BODYSHOP.EDIT} element={<CreateVehicleBodyShop isEdit={true} />} />
       
         <Route path={APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.HOME} element={<VehicleDealershipVehicleDealScreen />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.CREATE} element={<CreateVehicleDealershipVehicleDeal />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.EDIT} element={<CreateVehicleDealershipVehicleDeal isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_RATE_CODE.HOME} element={<RateAdminRateCodeScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATE_CODE.CREATE} element={<CreateRateAdminRateCode />} />
         <Route path={APP_URLS.RATEADMIN_RATE_CODE.EDIT} element={<CreateRateAdminRateCode isEdit={true} />} />

         <Route path={APP_URLS.RATEADMIN_RATE_PLAN.HOME} element={<RateAdminRatePlanScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATE_PLAN.CREATE} element={<CreateRateAdminRatePlan />} />
         <Route path={APP_URLS.RATEADMIN_RATE_PLAN.EDIT} element={<CreateRateAdminRatePlan isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_RATE_TYPES.HOME} element={<RateAdminRateTypesScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATE_TYPES.CREATE} element={<CreateRateAdminRateTypes />} />
         <Route path={APP_URLS.RATEADMIN_RATE_TYPES.EDIT} element={<CreateRateAdminRateTypes isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.HOME} element={<RateAdminLorAdjustmentsScreen />} />
         <Route path={APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.CREATE} element={<CreateRateAdminLorAdjustments />} />
         <Route path={APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.EDIT} element={<CreateRateAdminLorAdjustments isEdit={true} />} />
       

         <Route path={APP_URLS.RATEADMIN_RATES.HOME} element={<RateAdminRatesScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATES.CREATE} element={<CreateRateAdminRates />} />
         <Route path={APP_URLS.RATEADMIN_RATES.EDIT} element={<CreateRateAdminRates isEdit={true} />} />
         <Route path={APP_URLS.RATEADMIN_RATES.COPY} element={<CreateRateAdminRates isCopy={true} />} />

         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.HOME} element={<RateAdminExtraProductCategoryScreen />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.CREATE} element={<CreateRateAdminExtraProductCategory />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.EDIT} element={<CreateRateAdminExtraProductCategory isEdit={true} />} />
         
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.HOME} element={<RateAdminExtraProductChargeScreen />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.CREATE} element={<CreateRateAdminExtraProductCharge />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.EDIT} element={<CreateRateAdminExtraProductCharge isEdit={true} />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.COPY} element={<CreateRateAdminExtraProductCharge isCopy={true} />} />
         
         <Route path={APP_URLS.RATEADMIN_NETWORK.HOME} element={<RateAdminNetworksScreen />} />
         <Route path={APP_URLS.RATEADMIN_NETWORK.CREATE} element={<CreateRateAdminNetworks />} />
         <Route path={APP_URLS.RATEADMIN_NETWORK.EDIT} element={<CreateRateAdminNetworks isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_BLACKOUTS.HOME} element={<RateAdminBlackoutsScreen />} />
         <Route path={APP_URLS.RATEADMIN_BLACKOUTS.CREATE} element={<CreateRateAdminBlackouts />} />
         <Route path={APP_URLS.RATEADMIN_BLACKOUTS.EDIT} element={<CreateRateAdminBlackouts isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_RATEMIRRORS.HOME} element={<RateAdminRateMirrorsScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATEMIRRORS.CREATE} element={<CreateRateAdminRateMirrors />} />
         <Route path={APP_URLS.RATEADMIN_RATEMIRRORS.EDIT} element={<CreateRateAdminRateMirrors isEdit={true} />} />
         <Route path="*" element={ <NotFound />} />
    </Routes>
  )
}
