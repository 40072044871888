

export const FETCH_RATEADMIN_RATES_LIST = {
    START: 'FETCH_RATEADMIN_RATES_LIST_START',
    SUCCESS: 'FETCH_RATEADMIN_RATES_LIST_SUCCESS',
    ERROR: 'FETCH_RATEADMIN_RATES_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RATEADMIN_RATES_INIT="FETCH_RATEADMIN_RATES_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RATEADMIN_RATES_SCREEN =
'INIT_CREATE_RATEADMIN_RATES_SCREEN';

export const CREATE_RATEADMIN_RATES = {
START: 'CREATE_RATEADMIN_RATES_START',
SUCCESS: 'CREATE_RATEADMIN_RATES_SUCCESS',
ERROR: 'CREATE_RATEADMIN_RATES_ERROR',
};

export const EDIT_RATEADMIN_RATES = {
START: 'EDIT_RATEADMIN_RATES_START',
SUCCESS: 'EDIT_RATEADMIN_RATES_SUCCESS',
ERROR: 'EDIT_RATEADMIN_RATES_ERROR',
};

export const FETCH_RATEADMIN_RATES_DETAIL = {
  START: 'FETCH_RATEADMIN_RATES_DETAIL_START',
  SUCCESS: 'FETCH_RATEADMIN_RATES_DETAIL_SUCCESS',
  ERROR: 'FETCH_RATEADMIN_RATES_DETAIL_ERROR',
};
export const EXPORT_RATEADMIN_RATES = {
  START: 'EXPORT_RATEADMIN_RATES_START',
  SUCCESS: 'EXPORT_RATEADMIN_RATES_SUCCESS',
  ERROR: 'EXPORT_RATEADMIN_RATES_ERROR',
};
export const RATEADMIN_RATESDD = {
  START: 'RATEADMIN_RATESDD_START',
  SUCCESS: 'RATEADMIN_RATESDD_SUCCESS',
  ERROR: 'RATEADMIN_RATESDD_ERROR',
};
export const RATEADMIN_RATEOPTIONDD = {
  START: 'RATEADMIN_RATEOPTIONDD_START',
  SUCCESS: 'RATEADMIN_RATEOPTIONDD_SUCCESS',
  ERROR: 'RATEADMIN_RATEOPTIONDD_ERROR',
};
export const RATEADMIN_RATENETWORKDD = {
  START: 'RATEADMIN_RATENETWORKDD_START',
  SUCCESS: 'RATEADMIN_RATENETWORKDD_SUCCESS',
  ERROR: 'RATEADMIN_RATENETWORKDD_ERROR',
};

export const RATEADMIN_RATEADJUSTMENT = {
  START: 'RATEADMIN_RATEADJUSTMENT_START',
  SUCCESS: 'RATEADMIN_RATEADJUSTMENT_SUCCESS',
  ERROR: 'RATEADMIN_RATEADJUSTMENT_ERROR',
  };

  export const FETCH_RATEADMIN_RATES_RATEADJUSTMENT = {
    START: 'FETCH_RATEADMIN_RATES_RATEADJUSTMENT_START',
    SUCCESS: 'FETCH_RATEADMIN_RATES_RATEADJUSTMENT_SUCCESS',
    ERROR: 'FETCH_RATEADMIN_RATES_RATEADJUSTMENT_ERROR',
  };

  export const RATEADMIN_RATEDELETE = {
    START: 'RATEADMIN_RATEDELETE_START',
    SUCCESS: 'RATEADMIN_RATEDELETE_SUCCESS',
    ERROR: 'RATEADMIN_RATEDELETE_ERROR',
  };