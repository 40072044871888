import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Link, useLocation } from "react-router-dom"
import styleCss from './style.module.css'
import Logoicon from '../../images/logo.png';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LanguageIcon from '@mui/icons-material/Language';
import DvrIcon from '@mui/icons-material/Dvr';
import LinkIcon from '@mui/icons-material/Link';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ThemeToggle from '../ThemeProvider/ToggleTheme';
import { useStyles } from './style';
import { menuList } from './menu';
import { APP_URLS } from '../../utils/constant';
import { removeWordAfterAt } from '../../utils';

export default function Header(props) {
    const classes = useStyles()
    const location = useLocation();
    const handleLogout = () => {
        localStorage.clear();
        window.location.replace('/');
    }
    const [isMenu, setIsMenu] = useState(false);
    const handleToggle = () => {
        setIsMenu(!isMenu)
    };
    return (
        <div>
            <Box className={classes.topnav}>
                <Container maxWidth="lg">
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box className={styleCss.logobox}>
                            <img className={styleCss.logo} src={Logoicon} alt="log" />
                        </Box>
                        <Box display="flex" >
                            <Stack direction="row" px={1} alignItems="center"  >


                                <Box className={classes.logout} display="flex" m={0.5} >
                                    <Typography>  {removeWordAfterAt(localStorage.getItem("username"))}</Typography>
                                    <ul>
                                      <li><Link to={APP_URLS.CHANGEPASSWORD}>Profile</Link> </li>
                                      <li onClick={handleLogout}>Logout</li>
                                    </ul>
                                   
                                    

                                </Box>

                                {/* <ThemeToggle /> */}


                            </Stack>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Box className={classes.menubox}>
                <Container maxWidth="lg">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" px={2} mb={1}>

                        <Box flex={"auto"}>



                            <Box className={classes.menubar}>
                                <Box display="flex" className={styleCss.mainmenu} >
                                    <ul>
                                        <li>
                                            <Link to="/" className={styleCss.anchortag_space}>
                                                <span className={` ${location.pathname === "/dashboard" ? styleCss.selected_item : ""}`} >
                                                    <DashboardIcon className={styleCss.icon} /> Dashboard
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className={styleCss.anchortag_space}>
                                                <span className={` ${location.pathname === "#" ? styleCss.selected_item : ""}`} >
                                                    <ManageAccountsIcon className={styleCss.icon} /> Operations <KeyboardArrowDownSharpIcon className={styleCss.icon} />
                                                </span>
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to={menuList?.taskAssignment?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.taskAssignment?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.taskAssignment.name} <ChevronRightIcon className={styleCss.rightarrow}/>
                                                        </span>
                                                    </Link>
                                                    <ul>
                                                    {menuList.taskAssignment.submenu.map(item=> (
                                                       <li key={item?.path}>
                                                        <Link to={item?.path}>
                                                        {item?.name}
                                                        </Link>
                                                        </li>  
                                                    ))}
                                                       
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to={menuList?.admin?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.admin?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.admin.name} <ChevronRightIcon className={styleCss.rightarrow}/>
                                                        </span>
                                                    </Link>
                                                    <ul>
                                                    {menuList.admin.submenu.map(item=> (
                                                       <li key={item?.path}>
                                                        <Link to={item?.path}>
                                                        {item?.name}
                                                        </Link>
                                                        </li>  
                                                    ))}
                                                       
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to={menuList?.financial?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.financial?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.financial.name} <ChevronRightIcon className={styleCss.rightarrow}/>
                                                        </span>
                                                    </Link>
                                                    <ul>
                                                    {menuList.financial.submenu.map(item=> (
                                                       <li key={item?.path}>
                                                        <Link to={item?.path}>
                                                        {item?.name}
                                                        </Link>
                                                        </li>  
                                                    ))}
                                                       
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to={menuList?.communication?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.communication?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.communication.name} <ChevronRightIcon className={styleCss.rightarrow}/>
                                                        </span>
                                                    </Link>
                                                    <ul>
                                                    {menuList.communication.submenu.map(item=> (
                                                        <li key={item?.path}>
                                                        <Link to={item?.path}>
                                                        {item?.name}
                                                        </Link>
                                                        </li>  
                                                    ))}
                                                       
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/" className={styleCss.anchortag_space}>
                                                <span className={`${styleCss.header_box} ${location.pathname === "/" ? styleCss.selected_item : ""}`} >
                                                    <DriveEtaIcon className={styleCss.icon} />    Booking Engine <KeyboardArrowDownSharpIcon className={styleCss.icon} />
                                                </span>
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="/location" className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === "/location" ? styleCss.selected_item : ""}`} >
                                                            Location
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={menuList?.settings?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.settings?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.settings.name} <ChevronRightIcon className={styleCss.rightarrow}/>
                                                        </span>
                                                    </Link>
                                                    <ul>
                                                    {menuList.settings.submenu.map(item=> (
                                                        <li key={item?.path}>
                                                        <Link to={item?.path}>
                                                        {item?.name}
                                                        </Link>
                                                        </li>  
                                                    ))}
                                                       
                                                    </ul>
                                                </li>
                                                <li>
                                                <Link to={menuList?.holidays?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.holidays?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.holidays.name} 
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li>
                                                <Link to={menuList?.reservations?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.reservations?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.reservations.name} 
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={menuList?.maintenance?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.maintenance?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.maintenance.name} <ChevronRightIcon className={styleCss.rightarrow}/>
                                                        </span>
                                                    </Link>
                                                    <ul>
                                                    {menuList.maintenance.submenu.map(item=> (
                                                       <li key={item?.path}>
                                                        <Link to={item?.path}>
                                                        {item?.name}
                                                        </Link>
                                                        </li>  
                                                    ))}
                                                       
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to={menuList?.claims?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.claims?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.claims.name} <ChevronRightIcon className={styleCss.rightarrow}/>
                                                        </span>
                                                    </Link>
                                                    <ul>
                                                    {menuList.claims.submenu.map(item=> (
                                                        <li key={item?.path}>
                                                        <Link to={item?.path}>
                                                        {item?.name}
                                                        </Link>
                                                        </li>  
                                                    ))}
                                                       
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to={menuList?.vehicles?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.vehicles?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.vehicles.name} <ChevronRightIcon className={styleCss.rightarrow}/>
                                                        </span>
                                                    </Link>
                                                    <ul>
                                                    {menuList.vehicles.submenu.map(item=> (
                                                       <li key={item?.path}>
                                                        <Link to={item?.path}>
                                                        {item?.name}
                                                        </Link>
                                                        </li>  
                                                    ))}
                                                       
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to={menuList?.incident?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.incident?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.incident.name} <ChevronRightIcon className={styleCss.rightarrow}/>
                                                        </span>
                                                    </Link>
                                                    <ul>
                                                    {menuList.incident.submenu.map(item=> (
                                                       <li key={item?.path}>
                                                        <Link to={item?.path}>
                                                        {item?.name}
                                                        </Link>
                                                        </li>  
                                                    ))}
                                                       
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to={menuList?.rateadministration?.path} className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === menuList?.rateadministration?.path ? styleCss.selected_item : ""}`} >
                                                           {menuList?.rateadministration.name} <ChevronRightIcon className={styleCss.rightarrow}/>
                                                        </span>
                                                    </Link>
                                                    <ul>
                                                    {menuList.rateadministration.submenu.map(item=> (
                                                       <li key={item?.path}>
                                                        <Link to={item?.path}>
                                                        {item?.name}
                                                        </Link>
                                                        </li>  
                                                    ))}
                                                       
                                                    </ul>
                                                </li>
                                              
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/" className={styleCss.anchortag_space}>
                                                <span className={`${location.pathname === "/" ? styleCss.selected_item : ""}`} >
                                                    <TrendingUpIcon className={styleCss.icon} />  Renters <KeyboardArrowDownSharpIcon className={styleCss.icon} />
                                                </span>
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="/Home" className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === "/Home" ? styleCss.selected_item : ""}`} >
                                                            Operations 1
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/" className={styleCss.anchortag_space}>
                                                <span className={`${location.pathname === "/" ? styleCss.selected_item : ""}`} >
                                                    <AssessmentIcon className={styleCss.icon} />  Report <KeyboardArrowDownSharpIcon className={styleCss.icon} />
                                                </span>
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="/Home" className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === "/Home" ? styleCss.selected_item : ""}`} >
                                                            Operations 1
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/" className={styleCss.anchortag_space}>
                                                <span className={`${location.pathname === "/" ? styleCss.selected_item : ""}`} >
                                                    <LanguageIcon className={styleCss.icon} />  Website <KeyboardArrowDownSharpIcon className={styleCss.icon} />
                                                </span>
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="/Home" className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === "/Home" ? styleCss.selected_item : ""}`} >
                                                            Operations 1
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/" className={styleCss.anchortag_space}>
                                                <span className={`${location.pathname === "/" ? styleCss.selected_item : ""}`} >
                                                    <DvrIcon className={styleCss.icon} />  System <KeyboardArrowDownSharpIcon className={styleCss.icon} />
                                                </span>
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="/Home" className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === "/Home" ? styleCss.selected_item : ""}`} >
                                                            Operations 1
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <Link to="/" className={styleCss.anchortag_space}>
                                                <span className={`${location.pathname === "/" ? styleCss.selected_item : ""}`} >
                                                    <LinkIcon className={styleCss.icon} />  Quick Links <KeyboardArrowDownSharpIcon className={styleCss.icon} />
                                                </span>
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="/Home" className={styleCss.anchortag_space}>
                                                        <span className={` ${location.pathname === "/Home" ? styleCss.selected_item : ""}`} >
                                                            Operations 1
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                </Box>
                            </Box>
                        </Box>

                    </Stack>
                </Container>
            </Box>
        </div>
    )
}
