import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_RATES_LIST, 
  TOAST, 
  FETCH_RATEADMIN_RATES_INIT,
  FETCH_RATEADMIN_RATES_DETAIL,
  EXPORT_RATEADMIN_RATES,
  CREATE_RATEADMIN_RATES,
  EDIT_RATEADMIN_RATES,
  INIT_CREATE_RATEADMIN_RATES_SCREEN,
  RATEADMIN_RATESDD,
  RATEADMIN_RATENETWORKDD,
  RATEADMIN_RATEOPTIONDD,
  RATEADMIN_RATEADJUSTMENT,
  FETCH_RATEADMIN_RATES_RATEADJUSTMENT,
  RATEADMIN_RATEDELETE
} from "../../../types/rateadminrates.type";
import { SET_DELETE_CONFIRMATION_POPUP_VISIBILITY } from "../../../types/rateadminextraproductcategory.type";

const formFieldValueMap2 = {
  id:null,
  "daily": 0,
  "monthly": 0,
  "rateId": 0,
  "userId": 0,
  "weekEnd": 0,
  "weekly": 0
}
  const formFieldValueMap = {
    id:null,
    rateOption:"",
    startDate:"",
  endDate: "",
  fkCategory: 0,
  fkLocationId: 0,
  fkNetworkId: 0,
  fkRateGroup: 0,
  fkRateTypeId: 0,
    status:1,
    userId:"",
    dailybaseRate:"15",
    dailybaseMiles:"15",
    dailyextraRate:"15",
    dailyextraMiles:"15",
    dailyhoursRate:"15",
  dailylor1st:"0.5",
  dailylor2nd:"0.5",
  dailylor3rd:"0.5",
  dailylor4th:"0.5",
  dailylor5th:"0.5",
  dailylor6th:"0.5",
  weekendbaseRate:"15",
  weekendbaseMiles:"15",
  weekendextraRate:"15",
  weekendextraMiles:"15",
  weekendhoursRate:"15",
  weekendlor1st :"0.5",
  weekendlor2nd :"0.5",
  weekendlor3rd :"0.5",
  weekendlor4th :"0.5",
  weekendlor5th :"0.5",
  weekendlor6th :"0.5",
  weeklybaseRate:"15",
  weeklybaseMiles:"15",
  weeklyextraRate:"15",
  weeklyextraMiles:"15",
  weeklyhoursRate:"15",
  weeklylor1st :"0.5",
  weeklylor2nd :"0.5",
  weeklylor3rd :"0.5",
  weeklylor4th :"0.5",

  monthlybaseMiles:"15",
  monthlybaseRate:"15",
  monthlyextraMiles:"15",
  monthlyextraRate:"15",
  monthlyhoursRate:"15",
  };
  
  const INITIAL_STATE = {
    rateAdminRatesListview: [],
    RatesDD:[],
    RateOptionsDD:[],
    RateNetworkDD:[],
    isRateAdminRatesDDSuccess: false,
    isRateAdminRatesDDError: false,
    isRateAdminRateOptionsDDSuccess: false,
    isRateAdminRateOptionsDDError: false,
    isRateAdminRateNetworkDDSuccess: false,
    isRateAdminRateNetworkDDError: false,

    isRateAdminRatesDeleteSuccess: false,
    isRateAdminRatesDeleteError: false,

    isLoading: false,
    error: null,
    formFieldValueMap,
    formFieldValueMap2,
    isRateAdminRateAdjSuccess: false,
    isRateAdminRateAdjError: false,
    rateAdjustmentlist:[],
    isRateAdminRateAdjListSuccess: false,
    isRateAdminRateAdjListError: false,
   

    isRateAdminRatesCreateSuccess: false,
    isRateAdminRatesCreateError: false,
    isRateAdminRatesDetailSuccess: false,
    isRateAdminRatesDetailError: false,
    isRateAdminRatesEditSuccess: false,
    isRateAdminRatesEditError: false,
    isRateAdminRatesExportSuccess: false,
    isRateAdminRatesExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        id,
        locationTitle,
        categoryTitle,
        rateCodeTitle,
        rateOptionTitle,
        rateType,
        networkTitle,
        startDate,
        endDate,
        daily,
        weekend,
        weekly,
        monthly
      } = item;
  
      const transformedValues = {
        id,
        locationTitle,
        categoryTitle,
        rateCodeTitle,
        rateOptionTitle,
        rateType,
        networkTitle,
        startDate,
        endDate,
        daily,
        weekend,
        weekly,
        monthly
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Location",
        dataKey: "locationTitle",
        extraProps: {
          isDropDownField:true,
          options: [],
          filterField: 'locationTitle',
          sortField: 'locationTitle',
          isSortable: true,
          isDateField: false,
          shouldFilter: true,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Category",
        dataKey: "categoryTitle",
        extraProps: {
          filterField: 'categoryTitle',
          sortField: 'categoryTitle',
          isSortable: true,
          isDateField: false,
          shouldFilter: true,
          isDropDownField:true,
          options:[],
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
       
      }, 
      {
        colLabel: "Network",
        dataKey: "networkTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: true,
          isDropDownField:true,
          options:[],
          filterField: 'networkTitle',
          sortField: 'networkTitle',
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Code",
        dataKey: "rateCodeTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: true,
          isDropDownField:true,
          options:[],
          filterField: 'rateCodeTitle',
          sortField: 'rateCodeTitle',
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Rate Option",
        dataKey: "rateOptionTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: true,
          isDropDownField:true,
          options:[],
          filterField: 'rateOptionTitle',
          sortField: 'rateOptionTitle',
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Type",
        dataKey: "rateType",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: true,
          isDropDownField:true,
          options:[],
          filterField: 'rateType',
          sortField: 'rateType',
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
     
      {
        colLabel: "startDate",
        dataKey: "startDate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "End Date",
        dataKey: "endDate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Daily",
        dataKey: "daily",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Weekend",
        dataKey: "weekend",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Weekly",
        dataKey: "weekly",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Monthly",
        dataKey: "monthly",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
    
    return {
      datas: { columns, rows },
    };
  };

  export const rateadminRatesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RATEADMIN_RATES_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RATEADMIN_RATES_DETAIL.START:
        case FETCH_RATEADMIN_RATES_RATEADJUSTMENT.START:
        
      case EXPORT_RATEADMIN_RATES.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RATEADMIN_RATES_LIST.START:
        case RATEADMIN_RATEDELETE.START:
      case CREATE_RATEADMIN_RATES.START:
        case RATEADMIN_RATEADJUSTMENT.START:
        case EDIT_RATEADMIN_RATES.START:
          
                  return { ...state, isLoading: true };
  
      case FETCH_RATEADMIN_RATES_LIST.SUCCESS: {
        const { page, data } = action.payload?.data;
       
        let resetState = {
          rateAdminRatesListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRateAdminRatesCreateSuccess: false,
          isRateAdminRatesCreateError: false,
        };
        if (!action.payload?.data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...state,
          rateAdminRatesListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RATEADMIN_RATES_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          rateAdminRatesListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          israteadminRatesDeletionSuccess: false,
          israteadminRatesDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RATEADMIN_RATES_DETAIL.SUCCESS: {
      const temp = action.payload;
      let rateGridData=[];
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
              if(key==="endDate"){
                temp[key] = new Date(action.payload[key])
              }
              if(key==="startDate"){
                temp[key] = new Date(action.payload[key])
              }
             
              
          }
        });
        // Rate ADJUSTMENT GIRD
        if(action.payload.rateAdjustGrids.length>0){ 
          rateGridData =action.payload.rateAdjustGrids
        }
        

//BASE
        if(action.payload.rateBaseDTO.length>0){ 
          let dailyBase = action.payload?.rateBaseDTO[0];
          let weekendBase = action.payload?.rateBaseDTO[1];
          let weeklyBase = action.payload?.rateBaseDTO[2];
          let monthlyBase = action.payload?.rateBaseDTO[3];
         
       //Daily
          temp["dailybaseRate"] = dailyBase?.rate;
          temp["dailybaseId"] = dailyBase?.id;
          temp["dailybaseMiles"] = dailyBase?.miles;

      //Weekend
      temp["weekendbaseRate"] = weekendBase?.rate;
      temp["weekendbaseId"] = weekendBase?.id;
      temp["weekendbaseMiles"] = weekendBase?.miles;
      //Weekly
      temp["weeklybaseRate"] = weeklyBase?.rate;
      temp["weeklybaseId"] = weeklyBase?.id;
      temp["weeklybaseMiles"] = weeklyBase?.miles;
      
      //Monthly
      temp["monthlybaseRate"] = monthlyBase?.rate;
      temp["monthlybaseId"] = monthlyBase?.id;
      temp["monthlybaseMiles"] = monthlyBase?.miles;
        }

//Extra Day	
        if(action.payload.rateExtraDayDTOS.length>0){ 
          let dailyextraRate = action.payload?.rateExtraDayDTOS[0];
          let weekendextraRate = action.payload?.rateExtraDayDTOS[1];
          let weeklyextraRate = action.payload?.rateExtraDayDTOS[2];
          let monthlyextraRate = action.payload?.rateExtraDayDTOS[3];
         
       //Daily
          temp["dailyextraRate"] = dailyextraRate?.rate;
          temp["dailyextraId"] = dailyextraRate?.id;
          temp["dailyextraMiles"] = dailyextraRate?.miles;

      //Weekend
      temp["weekendextraRate"] = weekendextraRate?.rate;
      temp["weekendextraId"] = weekendextraRate?.id;
      temp["weekendextraMiles"] = weekendextraRate?.miles;
      //Weekly
      temp["weeklyextraRate"] = weeklyextraRate?.rate;
      temp["weeklyextraId"] = weeklyextraRate?.id;
      temp["weeklyextraMiles"] = weeklyextraRate?.miles;
      
      //Monthly
      temp["monthlyextraRate"] = monthlyextraRate?.rate;
      temp["monthlyextraId"] = monthlyextraRate?.id;
      temp["monthlyextraMiles"] = monthlyextraRate?.miles;
        }

        
//Hours
if(action.payload.rateExtraHourDTOS.length>0){ 
  let dailyhoursRate = action.payload?.rateExtraHourDTOS[0];
  let weekendhoursRate = action.payload?.rateExtraHourDTOS[1];
  let weeklyhoursRate = action.payload?.rateExtraHourDTOS[2];
  let monthlyhoursRate = action.payload?.rateExtraHourDTOS[3];
 
//Daily
  temp["dailyhoursRate"] = dailyhoursRate?.rate;
  temp["dailyhoursId"] = dailyhoursRate?.id;

//Weekend
temp["weekendhoursRate"] = weekendhoursRate?.rate;
temp["weekendhoursId"] = weekendhoursRate?.id;
//Weekly
temp["weeklyhoursRate"] = weeklyhoursRate?.rate;
temp["weeklyhoursId"] = weeklyhoursRate?.id;

//Monthly
temp["monthlyhoursRate"] = monthlyhoursRate?.rate;
temp["monthlyhoursId"] = monthlyhoursRate?.id;
}


// LOR ADJ

if(action.payload.rateLorAdjustmentDTOS.length>0){ 
  let lor= action.payload.rateLorAdjustmentDTOS;

  //Daily
  temp["dailylor1st"] = lor[0]?.rate;
  temp["dailylor1stId"] = lor[0]?.id;

  temp["dailylor2nd"] = lor[1]?.rate;
  temp["dailylor2ndId"] = lor[1]?.id;

  temp["dailylor3rd"] = lor[2]?.rate;
  temp["dailylor3rdId"] = lor[2]?.id;

  temp["dailylor4th"] = lor[3]?.rate;
  temp["dailylor4thId"] = lor[3]?.id;

  temp["dailylor5th"] = lor[4]?.rate;
  temp["dailylor5thId"] = lor[4]?.id;

  temp["dailylor6th"] = lor[5]?.rate;
  temp["dailylor6thId"] = lor[5]?.id;

  //Weekend

  temp["weekendlor1st"] = lor[6]?.rate;
  temp["weekendlor1stId"] = lor[6]?.id;

  temp["weekendlor2nd"] = lor[7]?.rate;
  temp["weekendlor2ndId"] = lor[7]?.id;

  temp["weekendlor3rd"] = lor[8]?.rate;
  temp["weekendlor3rdId"] = lor[8]?.id;

  temp["weekendlor4th"] = lor[9]?.rate;
  temp["weekendlor4thId"] = lor[9]?.id;

  temp["weekendlor5th"] = lor[10]?.rate;
  temp["weekendlor5thId"] = lor[10]?.id;

  temp["weekendlor6th"] = lor[11]?.rate;
  temp["weekendlor6thId"] = lor[11]?.id;


  //Weekly

  temp["weeklylor1st"] = lor[12]?.rate;
  temp["weeklylor1stId"] = lor[12]?.id;

  temp["weeklylor2nd"] = lor[13]?.rate;
  temp["weeklylor2ndId"] = lor[13]?.id;

  temp["weeklylor3rd"] = lor[14]?.rate;
  temp["weeklylor3rdId"] = lor[14]?.id;

  temp["weeklylor4th"] = lor[15]?.rate;
  temp["weeklylor4thId"] = lor[15]?.id;
}
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        rateAdjustmentlist:rateGridData,
        isRateAdminRatesDetailSuccess: true,
      };
    }
    case FETCH_RATEADMIN_RATES_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminRatesDetailSuccess: false,
        isRateAdminRatesDetailError: true,
      };
    }

    //DD
   //DD
   case RATEADMIN_RATESDD.SUCCESS: {
    const temp = action.payload;

    return {
      ...state,
      isLoading: false,
      RatesDD:temp,
      isRateAdminRateCodeDDSuccess: true,
      isRateAdminRateCodeDDError: false,
    };
  }
  case RATEADMIN_RATESDD.ERROR: {
    const { error } = action.payload;

    return {
      ...state,
      error,
      isRateAdminRateCodeDDSuccess: false,
      isRateAdminRateCodeDDError: true,
    };
  }
  //END DD
    
  //DELETE

  case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
    const shouldOpenDeleteConfirmationPopup = action.payload;
    return {
      ...state,
      shouldOpenDeleteConfirmationPopup,
      isRateAdminRatesDeleteSuccess: false,
      isRateAdminRatesDeleteError: false,
    };
  }
  case RATEADMIN_RATEDELETE.SUCCESS: {
    const temp = action.payload;

    return {
      ...state,
      isLoading: false,
      RatesDD:temp,
      isRateAdminRatesDeleteSuccess: true,
      isRateAdminRatesDeleteError: false,
      shouldOpenDeleteConfirmationPopup: false,
    };
  }
  case RATEADMIN_RATEDELETE.ERROR: {
    const { error } = action.payload;

    return {
      ...state,
      error,
      isRateAdminRatesDeleteSuccess: false,
      isRateAdminRatesDeleteError: true,
      shouldOpenDeleteConfirmationPopup: false,
    };
  }
  //END DD
    
     //Network DD
     case RATEADMIN_RATENETWORKDD.SUCCESS: {
      const temp = action.payload;
      return {
        ...state,
        isLoading: false,
        RateNetworkDD:temp,
        isRateAdminRateNetworkDDSuccess: true,
        isRateAdminRateNetworkDDError: false,
      };
    }
    case RATEADMIN_RATENETWORKDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminRateNetworkDDSuccess: false,
        isRateAdminRateNetworkDDError: true,
      };
    }
    //END DD
 //OPtions DD
 case RATEADMIN_RATEOPTIONDD.SUCCESS: {
  const temp = action.payload;
  return {
    ...state,
    isLoading: false,
    RateOptionsDD:temp,
    isRateAdminRateOptionsDDSuccess: true,
    isRateAdminRateOptionsDDError: false,
  };
}
case RATEADMIN_RATEOPTIONDD.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isRateAdminRateOptionsDDSuccess: false,
    isRateAdminRateOptionsDDError: true,
  };
}
//END DD

// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End
// Rate ADJ Details
case FETCH_RATEADMIN_RATES_RATEADJUSTMENT.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isRateAdminRateAdjListSuccess: false,
    isRateAdminRateAdjListError: true,
  };
}
case FETCH_RATEADMIN_RATES_RATEADJUSTMENT.SUCCESS: {
  const temp = action.payload;
  if (action.payload && typeof action.payload === "object") {
    Object.keys(action.payload).forEach((key) => {
      if (formFieldValueMap2.hasOwnProperty(key)) {
          temp[key] = action.payload[key];  
      }
    });
  }

  return {
    ...state,
    isLoading: false,
    formFieldValueMap2: temp,
    isRateAdminRateAdjListSuccess: true,
    isRateAdminRateAdjListError: false,
  };
}
//Rate ADJ Details END
// Export Start
case EXPORT_RATEADMIN_RATES.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRateAdminRatesExportSuccess: false,
    isRateAdminRatesExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RATEADMIN_RATES.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"rates.xls");
   return {
    ...state,
    isRateAdminRatesExportSuccess: true,
    isRateAdminRatesExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RATEADMIN_RATES.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminRatesEditSuccess: false,
    isRateAdminRatesEditError: true,
  };
}

case EDIT_RATEADMIN_RATES.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminRatesEditSuccess: true,
    isRateAdminRatesEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RATEADMIN_RATES_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RATEADMIN_RATES.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminRatesCreateSuccess: false,
    isRateAdminRatesCreateError: true,
  };
}
case CREATE_RATEADMIN_RATES.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminRatesCreateSuccess: true,
    isRateAdminRatesCreateError: false,
  };
}

//End
  


case RATEADMIN_RATEADJUSTMENT.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminRateAdjSuccess: false,
    isRateAdminRateAdjError: true,
  };
}
case RATEADMIN_RATEADJUSTMENT.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminRateAdjSuccess: true,
    isRateAdminRateAdjError: false,
  };
}

//End ADJ


      case TOAST.RESET: {
        return {
          ...state,
          isRateAdminRatesCreateSuccess: false,
          isRateAdminRatesCreateError: false,
          isRateAdminRatesEditError: false,
          isRateAdminRatesEditSuccess: false,
          isRateAdminRatesDetailError: false,
          isRateAdminRateAdjSuccess: false,
          isRateAdminRateAdjError: false,
          isRateAdminRateAdjListSuccess:false,
          isRateAdminRateAdjListError:false
        };
      }
      default:
        return state;
    }
  };
  